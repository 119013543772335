/* EXAMPLE STYLES
-------------------------------*/
body {
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
}

nav {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;

    a {
        margin-right: 8px;
    }
}

h1 {
    font-weight: normal;
    margin-top: 0;
}

input[type="text"] {
    display:block;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

.credits {
    margin-top: 30px;
    border-top: 1px solid #ddd;
    text-align: center;
}

